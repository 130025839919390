import {LessonCreationService} from '@modules/activities/core/lessons/services/lesson-creation.service';
import {TypologiesService} from '@modules/activities/core/typologies/typologies.service';
import {TypologyLabel} from '@modules/activities/core/typologies/typology.label';
import {combineLatest, EMPTY, Observable, of, ReplaySubject, Subject, Subscription, switchMap} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {CollectionOptionsInterface, CollectionPaginator, DataCollection, DataEntity, InterfaceError, OctopusConnectService} from 'octopus-connect';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {CommunicationCenterService} from '@modules/communication-center';
import {TranslateService} from '@ngx-translate/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {Roles} from 'shared/models';
import {MarkerInterface, MetadataInterface} from '../lessons-interfaces.interface';
import {
    ActivityGranule,
    AnswerResultInterface, LessonGranulePaginatedCollection,
    LessonGranuleEntity,
    LessonReferenceEntity,
    UpdatableActivityGranule
} from '@modules/activities/core/models';
import {ActivitiesService} from '../../activities.service';
import {EditLessonDialogComponent} from '../lessons-list/edit-lesson-dialog/edit-lesson-dialog.component';
import {localizedDate, localizedTime} from 'shared/utils/datetime';
import {AuthenticationService} from '@modules/authentication';
import * as _ from 'lodash-es';
import {forEach, isArray, merge} from 'lodash-es';
import {baseAppUrl, filterByCurrentYearByDefault} from '../../../../../settings';
import {FlagService} from 'shared/flag.service';
import {LessonToolDataCommunicationCenterInterface} from '../../services/generic-plugins.service';
import {combineLatestWith, filter, map, mergeMap, take, tap} from 'rxjs/operators';
import {PluginSetting} from '../../models/plugin.setting';
import {LessonMetadataDialogComponent} from '@modules/activities/core/lessons/lessons-list/lesson-metadata-dialog/lesson-metadata-dialog.component';
import {NavigateToLessonOptions} from '@modules/activities/core/models/lessonsActivityRoutes';
import {EventService} from 'shared/event.service';
import {LessonsConfigurationService} from './lessons-configuration.service';
import {LauncherComponent} from '@modules/activities/core/lessons/lessons-list/launcher/launcher/launcher.component';
import {TypedDataCollectionInterface, TypedDataEntityInterface} from 'shared/models/octopus-connect';
import {ActivityGranuleAttributes} from '@modules/activities/core/models/activities/activity-granule.attributes';
import {ItemAnswerStateEnum} from '@modules/activities/core/models/item-answer-state.enum';
import {EditLessonWarningComponent} from '@modules/activities/core/lessons/lessons-list/lessons-tab/edit-lesson-warning/edit-lesson-warning.component';
import {FormDialogService} from 'fuse-core/components/form-dialog/form-dialog.service';
import {ButtonListDialogComponent} from 'fuse-core/components/button-list/button-list-dialog/button-list-dialog.component';
import {ActivityCreationService} from "@modules/activities/core/activity-creation.service";

export type Type = {
    label: TypologyLabel,
    type: 'LESSON' | 'VIDEO' | 'SUMMARY' | 'MULTI' | string
    dataFromStep: { stepIndex: number, readOnly: boolean},
    summary: boolean,
    config: unknown,
    subLessonType: ('POLL' | 'SUMMARY')[],
    activity_content: number
}

type DuplicateLessonEntity = TypedDataEntityInterface<{
    '$originalGranuleLesson ': unknown // c'est volontaire, on récupère un objet qui ne semble pas être "api proof"
    duplicateGranuleLesson: {
        // id de la nouvelle lesson
        nid: string
    } & unknown // c'est volontaire, on récupère un objet qui ne semble pas être "api proof"
    // id de la lesson origine
    duplicateId: string
}>

/** Rappel :
 * get user lesson : filter: {author: user.id}
 * get lesson models : filter: {role: this.getAllowedRoleIdsForModelsCreation(), typology; null}
 * no sub lessons : filter: { multi_step: 0}
 */

@Injectable()
export class LessonsService {
    // TODO déplacer ca dans un service propre a l'execution actuelle d'une lesson
    public activities: DataEntity[] = [];
    public forms: DataEntity[] = [];
    public onLessonUpdate = new Subject<LessonGranuleEntity>();
    public selectedLessonId: string;
    public currentUser: DataEntity;
    /** @deprecated déplacer ca dans un service propre a l'execution actuelle d'une lesson voir meme pas dans ce module */
    public currentAssignment: DataEntity;
    // TODO déplacer ca dans un service propre a l'execution actuelle d'une lesson
    public lessonButtonClicked = new Subject<boolean>();
    public userLessonsPaginated: LessonGranulePaginatedCollection;
    public lessonsPaginated: LessonGranulePaginatedCollection;
    public favoritesLessonsPaginated: LessonGranulePaginatedCollection;
    // TODO c'est au composant de gérer ça
    public selectedTabIndex = 0;
    // TODO déplacer ca dans un service propre a l'execution actuelle d'une lesson
    public currentLesson: LessonGranuleEntity;
    public exitLessonUrl = '';
    // TODO c'est au composant de gérer ça
    public btnSave = false;
    // TODO c'est au composant de gérer ça
    public savingAssignment = false;
    public activityIdChange = new Subject<boolean>();
    // TODO c'est au composant de gérer ça
    public showSpinner: boolean;
    // TODO c'est au composant de gérer ça
    public isShareableCommunity: boolean;
    // TODO c'est au composant de gérer ça
    public isShareableModel: boolean;
    // TODO c'est au composant de gérer ça
    public showOnlySharedCommunityLesson: boolean;
    public assignationTypes: DataEntity[] = [];
    // subLessonProgressScoreList est un tableau d'objet sous le format { 4587 (id de la sous lesson 'questionSet') : score de la progression de la sous lesson}
    public subLessonProgressScoreList: { [key: string]: number } = {};
    private lessonsObservable: Observable<LessonGranuleEntity[]>;
    private userLessonsObservable: Observable<LessonGranuleEntity[]>;
    private favoritesLessonsObservable: Observable<LessonGranuleEntity[]>;
    private formsObservable: Observable<DataEntity[]>;
    private sequencesSubscription: Subscription;
    private lessons: LessonGranuleEntity[] = [];
    private userLessons: LessonGranuleEntity[] = [];
    private byRoleLessons: LessonGranuleEntity[] = [];
    private formsObs: { [key: string]: ReplaySubject<DataEntity> } = {};
    private lessonsObs: { [key: string]: ReplaySubject<LessonGranuleEntity> } = {};
    // TODO déplacer ca dans un service propre a l'execution actuelle d'une lesson
    private selectedActivity: ActivityGranule;
    private activitiesToClean: ActivityGranule[] = [];
    private activitiesToDelete: ActivityGranule[] = [];
    private selectedActivityInSubLesson: ActivityGranule;
    private currentSubLessonContentEdited: ActivityGranule[] = [];
    /**
     * Contains all typologies.
     * Filled by the backend.
     */
    private currentActivitiesEdited: ActivityGranule[];
    private lessonEditorWithStepConfig: { callback: () => void, activities: unknown };
    private roleNameToIdMapping: { string: number }[];
    public setCurrentLessonId: string | number;

    private activityCreationService = inject(ActivityCreationService);
    private activitiesService = inject(ActivitiesService);
    private authenticationService = inject(AuthenticationService);
    private communicationCenter = inject(CommunicationCenterService);
    private dialog = inject(MatDialog);
    private eventService = inject(EventService);
    private flagService = inject(FlagService);
    private formDialogService = inject(FormDialogService);
    private lessonCreationService = inject(LessonCreationService);
    private lessonsConfigurationService = inject(LessonsConfigurationService);
    private octopusConnect = inject(OctopusConnectService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private translate = inject(TranslateService);
    private typologiesService = inject(TypologiesService);

    constructor(
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => this.route),
            map(shadowRoute => {
                while (shadowRoute.firstChild) {
                    shadowRoute = shadowRoute.firstChild;
                }
                return shadowRoute;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => {
                if (route.params) {
                    return route.params;
                } else {
                    return null;
                }
            }))
            .subscribe((params: Params) => {
                if (params['formId']) {
                    if (!this.selectedLessonId) {
                        this.selectedLessonId = params['formId'];
                    }
                } else if (params['lessonId']) {
                    if (!this.selectedLessonId) {
                        this.selectedLessonId = params['lessonId'];
                    }
                } else {
                    this.selectedLessonId = null;
                }

                if (params['activityId']) {
                    this.activityIdChange.next(true);
                }

            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('userData')
            .subscribe((data: DataEntity) => {
                this.currentUser = data;
                if (data) {
                    this.activitiesService
                        .getAssignationTypes()
                        .subscribe((assignation_types) => {
                            this.assignationTypes = assignation_types;
                        });
                    // this.postAuthentication();
                } else {
                    this.postLogout();
                }
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('loadLessons')
            .subscribe(() => {
                this.loadCurrentUserOwnedLessons();
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('current')
            .subscribe((data) => {
                this.currentAssignment = data;
                /*userId, expires, state*/
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('saving')
            .subscribe((saving: boolean) => {
                this.savingAssignment = saving;
            });

        this.communicationCenter
            .getRoom('assignment')
            .getSubject('unassign')
            .subscribe((node) => {
                switch (node.type) {
                    case 'lesson':
                        this.unlockLesson(node.id);
                        break;
                    case 'form':
                    default:
                        break;
                }
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject<ActivityGranule[]>('addResources')
            .subscribe(resources => {
                this.setActivitiesOfSelectedLesson(resources);
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('selectionLessonEditorWithStepConfig')
            .subscribe((val: { activities: ActivityGranule[], callback: () => void }) => {
                this.currentActivitiesEdited = val.activities;
                this.lessonEditorWithStepConfig = val;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('addResourceToActivity')
            .subscribe((resource: unknown) => {
                this.showSpinner = true;
                this.editActivityVideo(resource)
                    .subscribe((entities) => {
                        this.showSpinner = false;
                        if (entities && entities.length) {
                            this.setCurrentActivity(entities[0]);
                        }
                        this.lessonEditorWithStepConfig.callback();
                    });

            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('updateCurrentLesson')
            .subscribe((selectedLesson: DataEntity) => {
                this.updateCurrentLesson(selectedLesson);
            });

        this.communicationCenter
            .getRoom('activities')
            .next('loadPaginatedLessonsCallback', (type, userRoles, searchValue, optionsInterface) => {
                return this.loadPaginatedLessons(type, userRoles, searchValue, optionsInterface);
            });

        this.communicationCenter
            .getRoom('activities')
            .next('loadPaginatedLessonGranuleCollection', (options: CollectionOptionsInterface) => {
                return this.loadPaginatedLessonGranuleCollection(options);
            });

        this.communicationCenter
            .getRoom('activities')
            .next('getAllowedRoleIdsForModelsCreationCallback', () => {
                return this.getAllowedRoleIdsForModelsCreation().join(); // get the role id for models creation
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('saveFromModalToLesson')
            .subscribe((lessonContent: DataEntity[]) => {
                this.saveFromModalToLesson(lessonContent);
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('saveProgress')
            .subscribe((progressValue: number) => {
                this.saveProgress(progressValue);
            });

        this.communicationCenter
            .getRoom('authentication')
            .getSubject('roles')
            .subscribe((roles) => {
                this.roleNameToIdMapping = roles;
            });

        this.communicationCenter
            .getRoom('activities')
            .getSubject('createActivities')
            .subscribe((data: { types: Type[], callbackSubject: Subject<Observable<DataEntity>[]> }) => {
                data.callbackSubject.next(this.activityCreationService.createActivitiesByTypes(data.types));
            });

        this.selectedTabIndex = this.lessonsConfigurationService.getSelectedTabIndex();

        this.communicationCenter
            .getRoom('lessons')
            .next('allowedRolesForModelsAssignation', this.lessonsConfigurationService.getAllowedRolesForModelsAssignation());

        this.communicationCenter
            .getRoom('lessons')
            .next('allowedRolesForModelsCreation', this.lessonsConfigurationService.getAllowedRolesForModelsCreation());

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('getActivities')
            .subscribe(({callbackSubject, lesson}: { lesson: DataEntity, callbackSubject: Subject<Observable<ActivityGranule[]>> }) =>
                callbackSubject.next(this.loadLessonActivities(lesson))
            );

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('getLesson')
            .subscribe(({callbackSubject, lessonId}: { lessonId: string | number, callbackSubject: Subject<Observable<DataEntity>> }) =>
                callbackSubject.next(this.loadLessonGranuleById(lessonId))
            );

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('getLessons')
            .subscribe(({callbackSubject, lessonIds}: { lessonIds: string[] | number[], callbackSubject: Subject<Observable<DataEntity[]>> }) =>
                callbackSubject.next(this.loadLessonByIds(lessonIds))
            );

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('playLesson')
            .subscribe(({assignment, navigateOptions}: { assignment: DataEntity, navigateOptions: NavigateToLessonOptions }) => {
                this.loadAndNavigateToLessonFromAssignment(assignment, navigateOptions);
            });

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('navigateToLesson')
            .subscribe(({lesson, navigateOptions, preview}: { lesson: LessonGranuleEntity, navigateOptions: NavigateToLessonOptions, preview?: boolean }) => {
                this.navigateToLesson(lesson, navigateOptions, preview);
            });

        this.initSchoolYearFilterValue().subscribe();

        this.communicationCenter
            .getRoom('lessons')
            .getSubject('openLessonMetadataDialog')
            .subscribe(({lessonId}: { lessonId: string | number }) => {
                this.openLessonMetadataDialog(lessonId).subscribe();
            });

        // TODO, ce subject n'existe pas dans la plateforme, s'il n'existe pas dans l'app c'est qu'il est a supprimer
        this.communicationCenter
            .getRoom('activities')
            .getSubject('setAssignmentProgress')
            .pipe(
                tap((data: { value: boolean }) => this.saveProgressInAssignmentWithMetacognition(data.value))
            ).subscribe();
    }

    public set editCurrentActivityInSubLesson(activity: ActivityGranule) {
        this.selectedActivityInSubLesson = activity;
    }

    public get currentActivityInSubLesson() {
        return this.selectedActivityInSubLesson;
    }

    public set editSubLessonContentEdited(activities: ActivityGranule[]) {
        this.currentSubLessonContentEdited = activities ? activities : [];
    }

    public get subLessonContentEdited() {
        return this.currentSubLessonContentEdited || [];
    }

    public get isAtLeastTrainerAndAssignmentExist(): boolean {
        return this.isAtLeastTrainer() && !!this.currentAssignment;
    }

    /**
     * Determines if in last activity of a lesson or a subLesson
     */
    public get isCurrentActivityLast(): boolean {
        if (this.subLessonContentEdited.length) {
            return this.subLessonContentEdited.length <= this.activitiesService.currentActivityIndex + 1;
        } else {
            return this.activitiesService.isCurrentActivityLast;
        }
    }

    // TODO déplacer ca dans un service propre a l'execution actuelle d'une lesson
    public get activityIndex(): number {
        if (this.subLessonContentEdited.length) {
            return this.activitiesService.currentActivityIndex;
        } else {
            return this.activitiesService.presentArrayElementIndex;
        }
    }

    /**
     * we can know if the user role is manager
     * @returns {boolean}
     */
    public get isUserManager(): boolean {
        return this.authenticationService.isManager();
    }

    // TODO voir si on met dans un service indépendant ou dans activities, mais pas dans lessons
    public loadMarkerTypes(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('marker_type').pipe(map((collection) => collection.entities));
    }

    // TODO mauvais service, ce n'est pas de la lesson mais de l'activité
    public createMarker(data: MarkerInterface): Observable<DataEntity> {
        return this.octopusConnect.createEntity('video_marker', data);
    }

    // TODO mauvais service, ce n'est pas de la lesson mais de l'activité
    public saveMarker(selectedMarker, data, markerIds) {
        // if edit marker
        if (selectedMarker) {
            const marker: DataEntity = new DataEntity(
                'video_marker',
                selectedMarker,
                this.octopusConnect,
                selectedMarker.id);

            marker.set('title', data.title);
            marker.set('description', data.description);
            marker.set('time', data.time);

            return marker.save();
        } else {
            // if create marker
            return this.createMarker(data).pipe(
                mergeMap((entity) => {
                    const allMarkers = [];
                    const media = this.getCurrentActivity().get('reference').activity_content[0];
                    allMarkers.push(...markerIds, entity.id);
                    const newMedia: DataEntity = new DataEntity(
                        'media',
                        media,
                        this.octopusConnect,
                        media.id);
                    newMedia.set('marker', allMarkers);
                    return newMedia.save();
                }));
        }

    }

    // TODO mauvais service, ce n'est pas de la lesson mais de l'activité
    public removeMarker(marker, markerIds) {
        const media = this.getCurrentActivity().get('reference').activity_content[0];
        const allMarkers = markerIds.filter((mark) => +mark !== +marker.id);
        const newMedia: DataEntity = new DataEntity(
            'media',
            media,
            this.octopusConnect,
            media.id);
        newMedia.set('marker', allMarkers);

        const currentMarker: DataEntity = new DataEntity(
            'video_marker',
            marker,
            this.octopusConnect,
            marker.id);

        return newMedia.save().pipe(
            mergeMap(() => {
                return currentMarker.remove();
            }));

    }

    public loadCurrentUserOwnedLessons(): Observable<DataEntity[]> {
        let needSubscribe = false;

        if (!this.userLessonsObservable) {
            needSubscribe = true;
            this.userLessonsObservable = this.octopusConnect.loadCollection('granule-lesson', {'owner': this.currentUser.id}).pipe(
                map(collection => collection.entities)
            ) as Observable<LessonGranuleEntity[]>;
        }

        const currentObservable: Observable<LessonGranuleEntity[]> = this.userLessonsObservable;

        if (needSubscribe) {
            currentObservable.pipe(take(1)).subscribe(entities => {
                this.userLessons = entities;
                this.lessons = this.userLessons.concat(this.byRoleLessons);

                entities.forEach((entity) => {
                    if (entity.id) {
                        if (this.lessonsObs[entity.id.toString()]) {
                            this.lessonsObs[entity.id.toString()].next(entity);
                        }
                    }
                });

                this.communicationCenter
                    .getRoom('activities')
                    .next('lessonsList', this.lessons);
            });
        }

        return currentObservable;
    }

    /**
     * Get an Observable about a list of activities ready to use (already loaded in {@see ActivitiesService}).
     * Only real activities are loaded, if an activity is a lesson for multi activity support.
     * This activity (lesson) will be ignored but the children activities will be loaded.
     * It's not a recursive way to load deeply activities of other Activites, the multi support work for a deep limit of 1 children.
     * @param lesson Lesson to load
     * @return Observable<DataEntity[]> List of activities
     */
    public loadLessonActivities(lesson: DataEntity): Observable<ActivityGranule[]> {
        const activitiesToLoad: number[] = [];
        const activityObsList: Observable<ActivityGranule>[] = [];
        const lessonObsList: Observable<void>[] = [];
        let loadedActivities: ActivityGranule[] = [];
        const observable = new ReplaySubject<ActivityGranule[]>(1);

        const getLoadActivityObservables = (activity) => {
            if (activitiesToLoad.indexOf(+activity.id) === -1) {
                activitiesToLoad.push(+activity.id);
                const loadedActivity = this.activitiesService.getActivityEntity(+activity.id);

                if (loadedActivity) {
                    loadedActivities.push(loadedActivity);
                } else {
                    activityObsList.push(this.activitiesService.loadActivitiesFromId(activity.id));
                }
            }
        };

        for (const activity of lesson.get('reference')) {
            if (activity['type'] !== 'lesson' || !this.activitiesService.settings.loadSubActivitiesOfAllSublesson) {
                getLoadActivityObservables(activity);
            } else {
                const lessonObs = this.loadLessonGranuleById(activity.id).pipe(map(subLesson => {
                    loadedActivities.push(<ActivityGranule><any>subLesson);
                    const subActivities = subLesson.get('reference');
                    for (const subActivity of subActivities) {
                        getLoadActivityObservables(subActivity);
                    }
                }));

                lessonObsList.push(lessonObs);
            }
        }

        (lessonObsList.length ? combineLatest(...lessonObsList) : of([]))
            .subscribe(() => {
                (activityObsList.length ? combineLatest(...activityObsList) : of([]))
                    .subscribe((activitiesEntity) => {
                        loadedActivities = activitiesEntity.concat(loadedActivities);
                        this.activitiesService.setCurrentActivities(loadedActivities);
                        observable.next(loadedActivities);
                    });
            });

        return observable;
    }

    /**
     * load paginated lesson
     * @param options
     */
    public loadPaginatedLessonGranuleCollection(options: CollectionOptionsInterface): Observable<{ entities: LessonGranuleEntity[], paginator: CollectionPaginator }> {
        const paginatedCollection: LessonGranulePaginatedCollection = this.octopusConnect.paginatedLoadCollection('lesson_granule_search', options) as LessonGranulePaginatedCollection;
        const collection$ = paginatedCollection.collectionObservable.pipe(map(collection => collection.entities));

        return collection$.pipe(
            mergeMap((entities: LessonGranuleEntity[]) => of({entities, paginator: paginatedCollection.paginator}))
        );
    }

    public loadPaginatedLessons(type?: 'currentUser' | 'byRole' | 'all', roles?: number[], searchValue?, filterOptions = {}, init?): Observable<LessonGranuleEntity[]> {
        let currentObservable: Observable<LessonGranuleEntity[]>;
        let needSubscribe = false;

        const defaultFilters = {
            'urlExtension': '',
            filter: {
                format: 'lesson'
            }
        };

        filterOptions = _.merge(defaultFilters, filterOptions);

        switch (type) {
            case 'currentUser': // TODO ne plus utiliser ce type, le parent doit savoir comment fonctionne l'enfant et fourni les parametres explicites
                needSubscribe = true;
                if (this.showOnlySharedCommunityLesson) {
                    filterOptions['filter']['shared'] = 1;
                } else {
                    if (!init) {
                        filterOptions['filter']['author'] = this.currentUser.id; // current user lessons
                    }
                }
                // WIP : waiting for connector sort
                // filterOptions['orderOptions']['field'] = 'changed';
                // filterOptions['orderOptions']['direction'] = 'DESC';
                // TODO utiliser loadPaginatedLessonGranulePaginatedCollection
                this.userLessonsPaginated = this.octopusConnect.paginatedLoadCollection('lesson_granule_search', filterOptions) as LessonGranulePaginatedCollection;
                this.userLessonsObservable = this.userLessonsPaginated.collectionObservable.pipe(map(collection => collection.entities));

                currentObservable = this.userLessonsObservable;
                break;
            case 'byRole': // On devrait ajouter ici "typology = null" dans les filtres // TODO ne plus utiliser ce type, le parent doit savoir comment fonctionne l'enfant et fourni les parametres explicites
                needSubscribe = true;
                filterOptions['filter']['role'] = roles ? roles.join(',') : null; // gestionnaire role id
                delete filterOptions['filter']['schoolyear']; // for manager : force school year to "all"
                this.lessonsPaginated = this.octopusConnect.paginatedLoadCollection('lesson_granule_search', filterOptions) as LessonGranulePaginatedCollection;
                // TODO utiliser loadPaginatedLessonGranulePaginatedCollection
                this.lessonsObservable = this.lessonsPaginated.collectionObservable.pipe(map(collection => collection.entities));

                currentObservable = this.lessonsObservable;
                break;
            case 'all': // TODO ne plus utiliser ce type, le parent doit savoir comment fonctionne l'enfant et fourni les parametres explicites
                // TODO utiliser loadPaginatedLessonGranulePaginatedCollection
                this.favoritesLessonsPaginated = this.octopusConnect.paginatedLoadCollection('lesson_granule_search', filterOptions) as LessonGranulePaginatedCollection;
                this.favoritesLessonsObservable = this.favoritesLessonsPaginated.collectionObservable.pipe(
                    map(collection => collection.entities));
                currentObservable = this.favoritesLessonsObservable;
        }

        if (needSubscribe) {
            currentObservable.subscribe(entities => {
                switch (type) {
                    case 'currentUser':
                        this.userLessons = entities;
                        break;
                    case 'byRole':
                        this.byRoleLessons = entities;
                        break;
                }

                this.lessons = this.userLessons.concat(this.byRoleLessons);
                entities.forEach((entity) => {
                    if (this.lessonsObs[entity.id.toString()]) {
                        this.lessonsObs[entity.id.toString()].next(entity);
                    }
                });

                this.communicationCenter
                    .getRoom('activities')
                    .next('lessonsList', this.lessons);
            });
        }

        return currentObservable;
    }

    /**
     * Loads the 10 last consulted lessons by the user
     * @returns Array of DataEntity lessons ordered by last consulted
     */
    public loadConsultedLessons(): Observable<DataEntity[]> {
        return this.octopusConnect.loadCollection('user-dashboard').pipe(
            take(1),
            map((collection: DataCollection) => {
                return collection.entities.flatMap((entity: DataEntity) => {
                    return entity.get('lessonsWidget').map((lesson) => {
                        return new DataEntity('granule', lesson, this.octopusConnect, lesson.id);
                    });
                });
            }));
    }

    // TODO dans le service des user-saves
    public loadUserSaves(contextId?: string, userId?: string | number): Observable<TypedDataEntityInterface<{ state: string, granule: (string | number)[] }>[]> {
        let filters;
        if (contextId) {
            filters = {context: contextId};
        } else if (this.currentAssignment) {
            filters = {context: this.currentAssignment.id};
        }
        if (userId) {
            filters.uid = userId;
        }

        return this.octopusConnect.loadCollection('user-save', filters).pipe(
            take(1),
            map((collection: TypedDataCollectionInterface<{ state: string, granule: (string | number)[] }>) => collection.entities));
    }

    // TODO dans le service des user-saves
    public loadLastUserSave(contextId?: string): Observable<DataEntity[]> {
        let filters;
        if (contextId) {
            filters = {context: contextId};
        }

        return this.octopusConnect.loadCollection('last-user-save', filters).pipe(
            take(1),
            map((collection: DataCollection) => collection.entities));
    }

    public loadForms(): Observable<DataEntity[]> {
        if (!this.formsObservable) {
            this.formsObservable = this.octopusConnect.loadCollection('granule-form').pipe(map(collection => collection.entities));
            this.formsObservable.subscribe((entities: DataEntity[]) => {
                this.forms = entities;

                entities.forEach((entity) => {
                    if (this.formsObs[entity.id.toString()]) {
                        this.formsObs[entity.id.toString()].next(entity);
                    }
                });

                this.communicationCenter
                    .getRoom('activities')
                    .getSubject('form-list')
                    .next(entities);
            });
        }

        return this.formsObservable;
    }

    // TODO - commenter utilité fonctionnement loadEntity
    public reloadForm(id: number | string): Observable<DataEntity> {
        const loadObs = this.octopusConnect.loadEntity('granule-form', id);

        loadObs.pipe(take(1))
            .subscribe(() => {
                // Force reload of granule-form collection
            });

        return loadObs;
    }

    public reloadLesson(id: number | string): Observable<LessonGranuleEntity> {
        const loadObsLesson = this.octopusConnect.loadEntity('granule-lesson', id) as Observable<LessonGranuleEntity>;

        loadObsLesson.pipe(take(1))
            .subscribe(() => {
                // Force reload of granule-lesson collection
            });

        return loadObsLesson;
    }

    private getForm(id: string): DataEntity {
        let form = null;

        this.forms.some((entity) => {
            if (entity.id.toString() === id) {
                form = entity;
                return true;
            }
        });

        return form;
    }

    public getLesson(id: string) {
        return this.lessons.find(entity => +entity.id === +id);
    }

    public getFormObs(id: string): Observable<DataEntity> {
        if (!this.formsObs[id]) {
            this.formsObs[id] = new ReplaySubject<DataEntity>(1);

            const form = this.getForm(id);
            if (form) {
                this.formsObs[id].next(form);
            }
        }

        return this.formsObs[id];
    }

    public getLessonObs(id: string, forceReload = false) {
        if (!this.lessonsObs[id]) {
            this.lessonsObs[id] = new ReplaySubject<LessonGranuleEntity>(1);

            if (forceReload) {
                this.loadLessonGranuleById(id).subscribe((lesson) => this.lessonsObs[id].next(lesson));
            } else {
                const lesson = this.getLesson(id);
                if (lesson) {
                    this.lessonsObs[id].next(lesson);
                } else {
                    this.loadLessonGranuleById(id).subscribe((rlesson) => this.lessonsObs[id].next(rlesson));
                }
            }
        } else if (forceReload) {
            this.lessonsObs[id].complete();
            this.lessonsObs[id] = new ReplaySubject<LessonGranuleEntity>(1);
            this.loadLessonGranuleById(id).subscribe((lesson) => this.lessonsObs[id].next(lesson));
        }

        return this.lessonsObs[id].asObservable();
    }

    // TODO déplacer ca dans un service propre a l'execution actuelle d'une lesson
    public setCurrentActivity(activity: ActivityGranule): void {
        this.selectedActivity = activity;
    }

    // TODO déplacer ca dans un service propre a l'execution actuelle d'une lesson
    public getCurrentActivity() {
        return this.selectedActivity;
    }

    private loadSurveyLinks(lessonId: string) {
        return this.octopusConnect.loadCollection('survey-link', {lesson: lessonId});
    }

    private loadSurveySaves(surveyId: string) {
        return this.octopusConnect.loadCollection('survey-save', {survey: surveyId});

    }

    public checkAndOpenSurvey() {
        this.loadSurveyLinks(this.currentLesson.id.toString())
            .pipe(
                take(1),
                mergeMap((surveyLinks) => {
                    return of(surveyLinks.entities).pipe(
                        combineLatestWith(this.loadUserSaves())
                    );
                }), take(1),
                // We want to pick the first survey link that has all its requirements fulfilled and check if the user has not already completed it
                mergeMap(([surveyLinks, userSaves]) => {
                    const surveySaves$ = surveyLinks.map((surveyLink) => {
                        if (!surveyLink.get('requirements') ||
                            surveyLink.get('requirements').every(activityId => userSaves
                                .find(userSave => userSave.get('granule').includes(activityId.toString()) && +userSave.get('grade') > 0))
                        ) {
                            return this.loadSurveySaves(surveyLink.id.toString());
                        } else {
                            return of(null);
                        }
                    });

                    return of(surveyLinks).pipe(
                        combineLatestWith(combineLatest(surveySaves$))
                    );
                }), take(1),
                map(([surveyLinks, surveySaves]) => {
                    for (let i = 0; i < surveyLinks.length; i++) {
                        if (surveySaves[i] && surveySaves[i].entities.length === 0) {
                            return surveyLinks[i];
                        }
                    }

                    return null;
                }),
                filter(surveyLink => !!surveyLink),
            ).subscribe((surveyLink) => {
            this.formDialogService.openForm(surveyLink);
        });
    }

    /**
     * common method to launch editing of lesson creating a new one etc.
     * @param id lesson id
     * @param action lesson form etc.
     * @param type edit, new etc.
     * @param autoSubscribe optional not launch the "subscribe" inside the method if false is passed
     * use because of compatibility with legacy code, and we need now to subscribe in component in some case
     * @param exitUrl
     */
    public launchEditor(id: string | number) {
        const path = ['/lesson-edition/editor'];
        if (!!id) {
            path.push(id.toString());
        }
        this.router.navigate(path);
        return of(EMPTY);
    }

    public launchCopyLessonEditor(action: string, id: string, type: string): Observable<DataEntity> {
        const data = {
            action,
            item: this.getLesson(id)
        };

        if (!data.item) {
            return this.loadLessonByIds([id]).pipe(
                tap(entityLesson => data.item = entityLesson[0]),
                mergeMap(() => this.openMetadataEditor(data, id, type))
            );
        } else {
            return this.openMetadataEditor(data, id, type);
        }
    }

    //  private launchEditLessonEditor(action: string, id: string, type: string): Observable<DataEntity> {
    //     const data = {
    //         action,
    //         item: id ? this.getLesson(id) : null,
    //     };
    //
    //     const dialogRef = this.dialog.open(EditLessonDialogComponent, {data});
    //
    //     return dialogRef.afterClosed().pipe(
    //         filter(result => !!result),
    //         mergeMap((result) =>
    //             this.saveFormOrLessonMetadata(id, result.form.getRawValue(), type)
    //                 .pipe(
    //                     mergeMap(() => {
    //                         // if theme is changed by user, save new theme in granule
    //                         const newThemeId = result.form.getRawValue().theme;
    //                         const oldThemeId = data.item.get('theme').id;
    //                         const newUsageId = result.form.getRawValue().usage;
    //                         const oldUsageId = data.item.get('usage');
    //                         if (newThemeId !== oldThemeId || newUsageId !== oldUsageId) {
    //                             const entityToSave: DataEntity = new DataEntity('granule', <any>data.item, this.octopusConnect, data.item.id);
    //                             if (newThemeId !== oldThemeId) {
    //                                 entityToSave.set('theme', newThemeId);
    //                             }
    //                             if (newUsageId !== oldUsageId) {
    //                                 entityToSave.set('usage', newUsageId);
    //                             }
    //                             return entityToSave.save();
    //                         }
    //                         return of({});
    //                     }),
    //                     map(() => data.item)
    //                 ))
    //     );
    // }

    //  private launchAnotherOldLessonEditor(action: string, id: string, type: string, exitUrl?: string): Observable<DataEntity> {
    //     switch (action) {
    //         case 'new': {
    //             return this.launchNewLessonEditor(action, id, type).pipe(
    //                 tap(() => this.showSpinner = false),
    //                 tap((entity) => {
    //                     this.lessons.push(entity);
    //                     this.communicationCenter.getRoom('lessons').next('openEditor', {id: entity.id.toString(), exitUrl});
    //                 })
    //             );
    //         }
    //         case 'edit': {
    //             return this.launchEditLessonEditor(action, id, type).pipe(
    //                 tap(() => this.communicationCenter
    //                     .getRoom('lessons')
    //                     .next('openEditor', {id, exitUrl})
    //                 )
    //             );
    //         }
    //         case 'copy': {
    //             return this.launchCopyLessonEditor(action, id, type).pipe(
    //                 tap(() => this.userLessonsPaginated.paginator.reload()),
    //                 filter(() => {
    //                     const roles: string[] = Object.keys(this.settings.openDialogInfoAfterDuplicateLesson);
    //                     return roles.length && roles.includes(this.authenticationService.accessLevel) ?
    //                         this.settings.openDialogInfoAfterDuplicateLesson[this.authenticationService.accessLevel] :
    //                         this.settings.openDialogInfoAfterDuplicateLesson.default;
    //                 }),
    //                 tap(() => this.showInfoLocationDuplicateLesson())
    //             );
    //         }
    //     }
    // }

    /**
     * open the dialog modal for deleting lesson not use in Erasme who use assignment
     * @param ressource DataEntity
     * @param autoSubscribe optional not launch the "subscribe" inside the method if false is passed
     * use because of compatibility with legacy code, and we need now to subscribe in component in some case
     */
    public openDeleteDialog(ressource: DataEntity, autoSubscribe = true) {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.data = {
            titleDialog: undefined,
        };


        dialogConfig.data.bodyDialog = this.translate.instant('generic.delete');
        dialogConfig.data.labelTrueDialog = this.translate.instant('generic.yes');
        dialogConfig.data.labelFalseDialog = this.translate.instant('generic.cancel');

        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                panelClass: 'lesson-form-dialog',
                data: dialogConfig.data
            }
        );

        const dialogCloseResult = dialogRef.afterClosed().pipe(
            mergeMap(result => {
                if (result === true) {
                    const entity = ressource.type !== 'granule-form' ? new DataEntity('granule', ressource.attributes, this.octopusConnect, ressource.id) : ressource;
                    return entity.remove().pipe(
                        map(success => {
                            if (success) {
                                if (this.userLessonsPaginated) {
                                    this.userLessonsPaginated.paginator.reload(); // refresh lessons ab
                                }

                                if (this.lessonsPaginated) {
                                    this.lessonsPaginated.paginator.reload(); // refresh models tab
                                }
                            }
                            return false;
                        }));
                } else {
                    return of(false);
                }
            }));

        if (autoSubscribe) {
            dialogCloseResult.subscribe();
        }

        return dialogCloseResult;
    }



    /**
     * TODO It's look like, the logic of editing a lesson are too much included in this service. But it's have to be separate between :
     * - Lesson service, able to do the exchange between front & back and the controls before it or able to do the generic actions about lessons
     * - A component, able to do the exchange between the service and the user, the controls before it and use the service generic actions for specific need
     * The service is shared between all the application as a Singleton. He don't have to be able to remember the temporary activities to delete, the current lesson, etc.
     * example :
     * - It's the role of the component to know which activities are to delete, which lesson are currently used, and the role of service to delete activity by example
     * - The button save is a 'View' context intelligence 0and the ability of be active or not it's a 'controller' context, the both has to be in a component
     * @param activities
     */
    public setActivitiesOfSelectedLesson(activities: ActivityGranule[]): void {
        this.getLessonObs(this.selectedLessonId.toString()).pipe(
            take(1))
            .subscribe((lessonEntity) => {
                const activityListAlreadyInLesson = lessonEntity.get('reference');

                activities.forEach(activity => {
                    const activityData = <any>activity.attributes;
                    activityData['id'] = activity.id.toString();
                    let activityIndex = activityListAlreadyInLesson.map(element => +element.id).indexOf(+activity.id);

                    if (activityIndex === -1) { // true if activity is new in lesson
                        activityListAlreadyInLesson.push(activityData);
                        this.activitiesToClean.push(activityData);
                    } else { // Don't know why we need to override
                        activityListAlreadyInLesson[activityIndex] = activityData;
                    }

                    activityIndex = this.activitiesToDelete.findIndex(element => +element.id === +activity.id);

                    if (activityIndex > -1) {
                        this.activitiesToDelete.splice(activityIndex, 1);
                    }
                });

                this.onLessonUpdate.next(lessonEntity);

                this.btnSave = true;
            });
    }

    /**
     * edit activity video : if activity-interface doesnt exist : this.currentActivitiesEdited[0].get('reference'), we create media
     * then we patch the reference 'activity_content_patch' with the id of the media (granule resource associated)
     * then we do the same for the others activities video that need to have the same reference attributes.
     */
    public editActivityVideo(resource): Observable<ActivityGranule[]> {
        const granule = {
            granule: resource.ressourceEntity.id
        };

        if (this.currentActivitiesEdited[0].get('reference') && typeof this.currentActivitiesEdited[0].get('reference') === 'string') {
            const obsAllActivityRef = this.currentActivitiesEdited.map((entity) => {
                return this.activitiesService.loadActivityInterface('id' in entity.get('reference') ? entity.get('reference')['id'] : entity.get('reference'));
            });
            const obsAllActivitymedia = this.currentActivitiesEdited.map(() => {
                return this.activityCreationService.createMedia(granule);
            });

            const entitiesMedia = [];

            return combineLatest(obsAllActivitymedia).pipe(
                mergeMap(medias => {
                    entitiesMedia.push(...medias);
                    return combineLatest(obsAllActivityRef).pipe(
                        mergeMap(refs => {
                            const obsActivityContent = entitiesMedia.map((media, index) => {
                                refs[index].set('activity_content_patch', media.id);
                                return refs[index].save();
                            });

                            return combineLatest(obsActivityContent).pipe(
                                mergeMap(refEntities => {
                                    const obsActivitiesSaved = this.currentActivitiesEdited
                                        .map((activity, index) => {
                                            const updatableActivity = activity as UpdatableActivityGranule;
                                            updatableActivity.set('reference', refEntities[index].id);
                                            updatableActivity.set('format', activity.get('format').id);
                                            return activity.save();
                                        });
                                    return combineLatest(obsActivitiesSaved);

                                }));
                        }));
                }));
        } else {

            const obsActivityMedia = this.currentActivitiesEdited
                .map((media, index) => {
                    const entity = new DataEntity(
                        'media',
                        this.currentActivitiesEdited[index].get('reference').activity_content[index],
                        this.octopusConnect,
                        this.currentActivitiesEdited[index].get('reference').activity_content[index].id
                    ) as ActivityGranule

                    entity.set('granule', resource.ressourceEntity.id);

                    return entity.save();
                });

            return combineLatest(obsActivityMedia);
        }
    }

    public removeActivityFromForm(activity: DataEntity): void {
        this.getFormObs(this.selectedLessonId.toString()).pipe(
            take(1))
            .subscribe((formEntity) => {
                const activityList = formEntity.get('reference');

                const activityIndex = activityList.findIndex((element) => +element.id === +activity.id);
                if (activityIndex > -1) {
                    activityList.splice(activityIndex, 1);
                }
            });
    }

    public removeActivityFromLesson(activity: ActivityGranule): void {
        this.getLessonObs(this.selectedLessonId.toString()).pipe(
            take(1))
            .subscribe((lessonEntity) => {
                const activityList = lessonEntity.get('reference');

                const activityIndex = activityList.findIndex((element) => +element.id === +activity.id);
                if (activityIndex > -1) {
                    activityList.splice(activityIndex, 1);
                    this.activitiesToDelete.push(activity);
                }

                this.btnSave = true;

            });
    }

    public saveFormOrLessonMetadata(id, metadata, type): Observable<DataEntity> {
        const updatedMetadataObs = new ReplaySubject<DataEntity>(1);
        const initializeObs = new Subject<DataEntity>();

        initializeObs.subscribe(entity => {
            if (entity) {
                const metadatas = <DataEntity>entity.getEmbed('metadatas');
                metadatas.set('title', metadata.title);
                metadatas.set('licenseContent', metadata.licenseContent);
                metadatas.set('description', metadata.description);
                metadatas.set('educationalLevel', metadata.educationalLevel);
                metadatas.set('chapters', metadata.chapters);
                metadatas.set('assignation_type', metadata.assignation_type);
                metadatas.set('difficulty', metadata.difficulty);
                metadatas.set('skills', metadata.skills);
                metadatas.set('thumbnail', metadata.thumbnail);
                metadatas.set('files', metadata.files);
                metadatas.set('source-author', metadata['source-author']);
                switch (type) {
                    case ('form'):
                        metadatas.save().subscribe(updatedMetadata => {
                            updatedMetadataObs.next(updatedMetadata);
                        });
                        break;
                    case ('lesson'):
                        metadatas.save().subscribe(updatedMetadata => {
                            updatedMetadataObs.next(updatedMetadata);
                        });
                        break;
                }
            }
        });

        switch (type) {
            case ('form'):
                initializeObs.next(this.getForm(id));
                break;
            case ('lesson'):
                this.getLessonObs(id).pipe(
                    take(1))
                    .subscribe(lesson => {
                        initializeObs.next(lesson);
                    });
                break;
        }

        return updatedMetadataObs;
    }

    public saveMetadatas(id, metadata, type): Observable<DataEntity> {
        let entity: LessonGranuleEntity;


        switch (type) {
            case ('form'):
                entity = this.getForm(id) as TypedDataEntityInterface<any>;
                break;
            case ('lesson'):
                entity = this.getLesson(id);
                break;
            default:
        }

        if (entity) {
            const metadatas = entity.getEmbed<MetadataInterface>('metadatas') as TypedDataEntityInterface<MetadataInterface>;
            metadatas.set('changed', entity.attributes.changed); // force metadatas changed date from granule

            switch (type) {
                case ('form'):
                    return metadatas.save();
                // break;
                case ('lesson'):
                    return metadatas.save();
                // break;
                default:
            }
        }

        return null;
    }

    public saveFormActivities(id): Observable<DataEntity> {
        const formEntity = this.getForm(id.toString());
        const activityList = formEntity.get('reference').map((activity) => +activity.id);
        const lessonStepEntity = new DataEntity('lesson', {lesson_step: []}, this.octopusConnect, formEntity.get('lesson_id'));

        // TODO - Restore DataEntity cleanup once octopus-connect is fixed
        /* this.activitiesToClean.forEach((activity) => {
            this.removeActivityFromForm(activity);
        });
        this.clearTempActivities(); */

        lessonStepEntity.set('lesson_step', activityList);

        let loading = false;
        let timeout = null;
        const saveObs = lessonStepEntity.save();
        saveObs.pipe(take(2)).subscribe(() => {
            if (!loading) {
                loading = true;
                timeout = setTimeout(() => this.reloadForm(formEntity.id), 500);
            } else {
                if (timeout) {
                    clearTimeout(timeout);
                    this.reloadForm(formEntity.id);
                }
            }
        });

        return saveObs.pipe(take(1));
    }

    public saveLessonActivities(id): Observable<DataEntity> {
        const lessonEntity = this.getLesson(id.toString());
        const activityList = lessonEntity.get('reference').map((activity) => +activity.id);
        const lessonStepEntity = new DataEntity('lesson', {lesson_step: []}, this.octopusConnect, lessonEntity.get('lesson_id'));

        this.activitiesToDelete.forEach((activity: DataEntity) => {
            if (activity.attributes.format.label === 'divider') {
                activity.remove();
            }
        });

        this.activitiesToDelete = [];

        lessonStepEntity.set('lesson_step', activityList);

        return lessonStepEntity.save().pipe(take(1));
    }

    public saveFromModalToLesson(lessonContent): void {
        this.currentLesson.set('reference', lessonContent);
        this.saveLessonActivities(+this.selectedLessonId).pipe(
            take(1))
            .subscribe(() => {
                this.saveMetadatas(this.selectedLessonId, [], 'lesson');
                this.clearTempActivities();
            });
    }

    public updateCurrentLesson(selectedLesson): void {
        this.currentLesson = selectedLesson;
        this.selectedLessonId = this.currentLesson.id.toString();
    }

    /**
     * Update assignment state for the current lesson based on the currently seen activity
     * Allows to check at each step of the lesson if there is an update to do depending on the activity type
     *
     * @param activity
     */
    public updateCurrentLessonState(activity?: TypedDataEntityInterface<ActivityGranuleAttributes>): void {
        if (this.isMyAssignment()) {
            if (this.isCurrentActivityLast) {
                const isNotActivity = !activity; // called without activity, should be from summary
                const isOnlyOneExternalActivity =
                    (this.subLessonContentEdited.length === 1 || this.activitiesService.activitiesArray.length === 1) &&
                    !!activity && activity.get('metadatas').typology.label === TypologyLabel.external;
                const isPending = this.currentAssignment.get('state_term').label === 'pending';

                if ((isOnlyOneExternalActivity || isNotActivity) && isPending) {
                    this.communicationCenter
                        .getRoom('assignment')
                        .next('changeState', {state: 'valid'});
                }
            }
        }
    }

    public clearTempActivities(): void {
        const lessonEntity = this.getLesson(this.selectedLessonId.toString());
        const activityList = lessonEntity.get('reference');

        this.activitiesToDelete.forEach(activity => {
            const activityData = activity.attributes;
            activityData['id'] = activity.id.toString();
            activityList.push(activityData as any);
        });
        this.activitiesToClean.forEach((activity) => {
            this.removeActivityFromForm(activity);
        });

        this.activitiesToClean = [];
        this.activitiesToDelete = [];

        this.btnSave = false;

    }

    public saveFeedbackUserSave(chooseActionForActivity: string, userSave: DataEntity): void {
        userSave.set('feedback', chooseActionForActivity);
        userSave.save();
    }

    public isLessonLaunched(): boolean {
        return this.isLessonTest() || this.isLessonAuto() || this.isLessonTraining() || this.isLessonEvaluation() || this.isAssignmentWithMetacognition();
    }

    public isLessonTest(): boolean {
        return !this.currentAssignment && this.getAllowedRolesForAutoAssignmentCreation().includes(this.authenticationService.accessLevel) === true;
    }

    public isLessonTraining(): boolean {
        return (this.currentAssignment &&
            this.currentAssignment.get('type_term') &&
            this.currentAssignment.get('type_term').label === 'training');
    }

    public isLessonAuto(): boolean {
        return (this.currentAssignment &&
            this.currentAssignment.get('type_term') &&
            this.currentAssignment.get('type_term').label === 'auto');
    }

    public isLessonEvaluation(): boolean {
        return this.currentAssignment &&
            this.currentAssignment.get('type_term') &&
            (
                this.currentAssignment.get('type_term').label === 'assessment' ||
                this.currentAssignment.get('type_term').label === 'homework'
            );
    }

    public isLessonValidated(): boolean {
        return this.currentAssignment &&
            this.currentAssignment.get('state_term') &&
            this.currentAssignment.get('state_term').label === 'closed';
    }

    public isLessonCorrected(): boolean {
        // zero value mean no date assignment used so no control to add
        if (!this.currentAssignment) {
            return false;
        }
        return +this.currentAssignment?.get('dates')?.value2 === 0 ? false :
            +this.currentAssignment?.get('dates')?.value2 &&
            +this.currentAssignment?.get('dates')?.value2 < (Date.now() / 1000);
    }

    public isMyAssignment(): boolean {
        return this.currentAssignment &&
            this.authenticationService.isMe(this.currentAssignment.get('assignated_user').uid);
    }

    public isTrainerSeeCorrection(): boolean {
        return this.isLessonEvaluation() &&
            (this.isAtLeastTrainer() || this.isLessonCorrected());
    }

    public lessonDuplication(id, type) {
        const saveObs = this.duplicateLessonById(id);
        saveObs.pipe(take(1)).subscribe((entity: DataEntity) => {
            switch (type) {
                case 'currentUser':
                    break;
                case 'byRole':
                    // redirect from models tab to user lessons tab
                    this.selectedIndexChange(0);
                    break;
            }
            if (this.userLessonsPaginated) {
                this.userLessonsPaginated.paginator.reload(); // refresh lessons ab
            }

            if (this.lessonsPaginated) {
                this.lessonsPaginated.paginator.reload(); // refresh models tab
            }
            return entity;
        }, (error: InterfaceError) => {
            return error;
        });

        return saveObs.pipe(take(1));

    }

    public duplicateLessonById(id: string | number): Observable<DuplicateLessonEntity> {
        return this.octopusConnect.createEntity('granule-lesson', {
            duplicateId: id,
            language: this.translate.currentLang,
        }) as Observable<DuplicateLessonEntity>;
    }

    selectedIndexChange(val): void {
        this.selectedTabIndex = val;
    }

    public checkAccess(user: string[]): boolean {
        if (user) {
            return this.authenticationService.hasLevel(user);
        }
        return false;
    }

    public isAtLeastTrainer(): boolean {
        return this.authenticationService.isAtLeastTrainer();
    }

    public isAtLeastParent(): boolean {
        return this.authenticationService.isAtLeastParent();
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeDate(date: number): string {
        return localizedDate(date);
    }

    /**
     *
     * @param date
     * @returns {string}
     */
    localeTime(date: number): string {
        return localizedTime(date);
    }

    getDate(resource: LessonGranuleEntity & { LocaleDateCreated: number, LocaleDateChanged: number }): string {
        if (resource) {
            let translateCreated = '';
            let translateChanged = '';
            let completeSentence = '';
            this.translate.get('generic.created_on').subscribe((translate) => {
                translateCreated = translate;
            });
            this.translate.get('generic.modify-date').subscribe((translate) => {
                translateChanged = translate;
            });

            completeSentence += ' ' + translateCreated;
            completeSentence += ' ' + resource.LocaleDateCreated;
            completeSentence += ' ' + '-';
            completeSentence += ' ' + translateChanged;
            completeSentence += ' ' + resource.LocaleDateChanged;
            return completeSentence;

        }
        return '';
    }

    /**
     * Loads multiple lessons by their identifiers.
     *
     * @param ids - An array of identifiers for the lessons to load.
     * @returns An observable that emits the loaded lesson entities.
     */
    loadLessonByIds(ids: (number | string)[]): Observable<LessonGranuleEntity[]> {
        return this.octopusConnect.loadEntities('granule-lesson', ids.map(Number)) as Observable<LessonGranuleEntity[]>;
    }

    /**
     * Loads a single lesson by its identifier.
     *
     * @param id - The identifier of the lesson to load.
     * @returns An observable that emits the loaded lesson entity.
     */
    public loadLessonGranuleById(id: string | number): Observable<LessonGranuleEntity> {
        return this.octopusConnect.loadEntity('granule-lesson', id) as Observable<LessonGranuleEntity>;
    }

    setAssignGrade(rate: number, oldRate: number): void {
        if (this.isLessonEvaluation() && this.lessonsConfigurationService.getGrade()) {
            let activitiesLength;
            if (!this.currentLesson) {
                const lessonIndex = this.lessons
                    .findIndex(lesson => +lesson.id === +this.currentAssignment.get('assignated_node').id);
                activitiesLength = this.lessons[lessonIndex].get('infos').activities;
            } else {
                activitiesLength = this.currentLesson.get('infos').activities;
            }

            const ratingBase = 20;
            const grade = +this.currentAssignment.get('grade');
            const sensitivity = 10000;

            const newNote = Math.floor(rate * (ratingBase / activitiesLength) * sensitivity) / sensitivity;
            const oldNote = Math.floor(oldRate * (ratingBase / activitiesLength) * sensitivity) / sensitivity;

            let newGrade;

            newGrade = Math.max((grade - oldNote) + newNote, 0);
            if (newGrade > 0) {
                newGrade = Math.min((grade - oldNote) + newNote, 20);
            }

            this.communicationCenter
                .getRoom('assignation')
                .getSubject('saveGrade')
                .next({grade: newGrade, idAssign: this.currentAssignment.id});
        }
    }

    /**
     * permet de sauver l'état de l'exercice dans l'assignation (si l'exercice est réussi, etc.)
     */
    setProgress(): void {
        if (this.currentAssignment && this.lessonsConfigurationService.getProgress()) {
            let progress = 0;
            let defaultValueProgress;

            let activitiesLength;

            const oldProgress = +this.currentAssignment.get('progress');

            if (!this.currentLesson) {
                const lessonIndex = this.lessons
                    .findIndex(lesson => +lesson.id === +this.currentAssignment.get('assignated_node').id);
                activitiesLength = this.lessons[lessonIndex].get('infos').activities;
            } else {
                activitiesLength = this.currentLesson.get('infos').activities;
            }

            defaultValueProgress = (100 / activitiesLength);

            progress += (oldProgress + defaultValueProgress);

            if (this.activitiesService.activityAnswerResult.length === this.activitiesService.activitiesArray.length &&
                this.activitiesService.activityAnswerResult.indexOf(2) === -1) {
                progress = 100;
            }

            this.saveProgress(progress);

        }
    }

    public loadRecapScreen(): void {
        let currentRoute = this.router.routerState.root;
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }

        this.activitiesService.playScreenStatus = 3;
        this.activitiesService.endScreenSeen = true;
        this.router.navigate(['recap'], {relativeTo: currentRoute});
    }

    public goBackToForm(): void {
        this.communicationCenter.getRoom('lessons').next('openEditor', this.selectedLessonId.toString());
    }

    public loadAndNavigateToLessonFromAssignmentId(assignmentId: string | number, navigateOptions?: NavigateToLessonOptions): void {
        const onAssignmentLoaded$: Subject<Observable<DataEntity>> = new Subject<Observable<DataEntity>>();
        onAssignmentLoaded$.pipe(
            mergeMap((assignment$: Observable<DataEntity>) => assignment$),
            take(1),
            tap((assignment: DataEntity) => this.loadAndNavigateToLessonFromAssignment(assignment, navigateOptions))
        ).subscribe();
        this.communicationCenter
            .getRoom('assignment')
            .getSubject('loadAndGetAssignmentById$')
            .next({context: assignmentId, onComplete: onAssignmentLoaded$});
    }

    public loadAndNavigateToLessonFromAssignment(assignment: DataEntity, navigateOptions?: NavigateToLessonOptions): void {
        let lessonId = navigateOptions.subLessonId ?? assignment.get('assignated_node').id;
        this.communicationCenter.getRoom('assignment').next('current', assignment);
        this.loadAndNavigateToLesson(lessonId, navigateOptions);
    }

    public loadAndNavigateToLesson(lessonId?: string | number, navigateOptions?: NavigateToLessonOptions): void {
        if (!lessonId) {
            throw new Error('No lesson ID could be determined.');
        }

        this.loadLessonGranuleById(lessonId).pipe(take(1))
            .subscribe(lesson => this.navigateToLesson(lesson, navigateOptions));
    }

    /**
     * Navigue vers la leçon spécifiée en vérifiant si elle doit être ouverte dans l'app ou dans le navigateur.
     *
     * @param lesson La leçon à ouvrir.
     * @param options Les options de navigation facultatives.
     * @param preview Indique si la leçon doit être lancée en mode de preview.
     */
    public navigateToLesson(lesson: LessonGranuleEntity, options?: NavigateToLessonOptions, preview?: boolean): void {
        this.lrsSendEvent(lesson, options, preview);

        if (this.shouldOpenInApp(lesson) && this.currentAssignment) {
            // TODO: attention il faut absolument que this.currentAssignment existe.
            this.openAssignmentInApp(lesson, this.currentAssignment.id, options);
        } else {
            this.startLesson(lesson, options, preview);
        }
    }

    public lrsSendTerminatedLessonEvent(lesson: LessonGranuleEntity, preview?: boolean): void {
        if (this.lessonsConfigurationService.getActivitiesBroadcastLifeCycle()) {
            // send lesson terminated statement
            this.communicationCenter
                .getRoom('lrs')
                .getSubject('lesson_end')
                .next({
                    lessonId: lesson.id,
                    concepts: lesson.get('metadatas') && lesson.get('metadatas').concepts ? lesson.get('metadatas').concepts : null,
                    preview: !preview ? false : true,
                });
        }
    }

    private lrsSendEvent(lesson: LessonGranuleEntity, options?: NavigateToLessonOptions, preview?: boolean): void {
        if (this.lessonsConfigurationService.getActivitiesBroadcastLifeCycle()) {
            // send lesson initialized statement
            this.communicationCenter
                .getRoom('lrs')
                .getSubject('lesson_init')
                .next({
                    lessonId: lesson.id,
                    concepts: lesson.get('metadatas') && lesson.get('metadatas').concepts ? lesson.get('metadatas').concepts : null,
                    preview: !preview && !options?.preview ? false : true,
                });

            // pour les traces, si pas de questionset, on envoie l'id du parcours parent
            if (!preview && !options?.preview) {
                const index = options?.startOnStepIndex || 0;
                let id = +lesson.id;
                if (lesson.get('reference')[index]?.type === 'lesson') {
                    id = +lesson.get('reference')[index].id;
                }
                if (index === 0) {
                    // on lance activité 0 donc c'est un "fresh start"
                    this.communicationCenter
                        .getRoom('lrs')
                        .getSubject('activity_initialize')
                        .next({id: `questionSet/${id}`, assignmentId: this.currentAssignment?.id || null});
                } else {
                    // on ne reprends pas sur l'activité 0 donc c'est une reprise d'exercice suspendue
                    this.communicationCenter
                        .getRoom('lrs')
                        .getSubject('activity_resume')
                        .next({id: `questionSet/${id}`, assignmentId: this.currentAssignment?.id || null});
                }
            }
        }
    }

    /**
     * permet d'ajouter des queryparams à une url
     * lorsque celle-ci est une string.
     * Attention ici on ne set pas des queryparams via le navigationExtras d'un route.navigate(...,navigationExtras)
     * exemple d'utilisation this.exitLessonUrl.
     * @param url
     * @param params
     * @private
     */
    private includeQueryParamsInUrl(url: string, params: { [key: string]: string | number }): string {
        for (const key in params) {
            if (!url.includes(key)) {
                if (params[key] && params[key] !== 'undefined') {
                    url = url + (url.includes('?') ? '&' : '?') + key + '=' + params[key];
                }
            }
        }
        return url;
    }

    /**
     * Launch the lesson player
     *
     * @param lesson La leçon à démarrer.
     * @param options Les options de navigation facultatives.
     * @param preview
     */
    private startLesson(lesson: LessonGranuleEntity, options?: NavigateToLessonOptions, preview?: boolean): void {
        // le code pose un soucis dans citizen code au lancement d'un parcours assigné depuis les lessons
        // je ne vois pas quel est son usage je commente donc le code si ça a un effet de bord il faudra le remettre
        // fais le en octobre 2024 à supprimer si dans 6 mois pas de retours
        /* if (lesson?.get('requirementsAccess') && !lesson?.get('requirementsAccess')?.access) {
             return;
         }*/
        const label = lesson.attributes.metadatas.assignation_type?.label || '';

        this.eventService.trackEvent(
            'Start lesson',
            (preview ? 'Start preview' : 'Start lesson') + ' ' + label,
            lesson.id + ' ' + lesson.attributes.metadatas.title,
        );

        if (preview || options?.preview) {
            this.communicationCenter.getRoom('assignment').next('current', null);
            this.activitiesService.resetActivitiesInCache();
            this.resetActivitiesArray();
        }

        this.activitiesService.pushLessonFromAssignment.next(lesson);
        this.activitiesService.currentLesson = lesson;
        this.activitiesService.playScreenStatus = 1;
        this.activitiesService.presentArrayElementIndex = 0;

        const flaggingId =
            typeof lesson.get('consulted') === 'object'
                ? (lesson.get('consulted') as any).flagging_id
                : undefined;

        this.flagService.updateFlagEntity(
            lesson,
            'node',
            'consulted',
            flaggingId,
        );

        let backUrl = options?.exitLessonUrl || this.router.url;
        if (options?.exitLessonUrl) {
            backUrl = this.includeQueryParamsInUrl(backUrl, {
                lessonId: `${lesson.id}`, // Faut pas faire ça, on sait pas d'où l'on vient, donc on sait pas si ce champ n'est pas utilisé pour autre chose
                assignmentId: `${options.assignmentId}`, // Idem
            });
        }
        this.exitLessonUrl = backUrl || this.router.url;

        if (this.lessonsConfigurationService.isActivitiesListMustBeDisplayed() && (!options || (options?.isActivitiesListMustBeDisplayed || options.startOnStepIndex === 0 && options.isActivitiesListMustBeDisplayed != false))) {
            const oldOptions = !options ? {} : options;
            options = _.merge({}, oldOptions, {isActivitiesListMustBeDisplayed: true, exitLessonUrl: this.exitLessonUrl});
        }

        if (this.isMyAssignment() && this.currentAssignment.get('state_term')?.label === 'assigned') {
            this.communicationCenter
                .getRoom('assignment')
                .next('changeState', {state: 'pending'});
        }

        this.activitiesService.constructUrlAndNavigateToLesson(
            +lesson.id,
            !!this.currentAssignment,
            false,
            options,
        );
    }

    /**
     * Détermine si la leçon spécifiée doit être ouverte dans l'app ou dans la plateforme
     *
     * @param lesson La leçon à vérifier.
     * @returns True si la leçon doit être ouverte dans l'application, False sinon.
     */
    private shouldOpenInApp(lesson: LessonGranuleEntity): boolean {
        return (
            lesson.attributes.metadatas.concepts.some(
                (c) => c && c.showIn && c.showIn.includes('app')
            ) &&
            this.lessonsConfigurationService.openLessonInApp() &&
            this.authenticationService.isLearner()
        );
    }

    /**
     * Ouvre la leçon spécifiée dans l'application en construisant l'URL correspondante avec les paramètres appropriés.
     *
     * @param lesson La leçon à ouvrir.
     * @param assignmentId
     * @param options paramètre contextuels de loncement de parcours
     */
    private async openAssignmentInApp(lesson: LessonGranuleEntity, assignmentId: string | number, options?: NavigateToLessonOptions): Promise<void> {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            bodyDialog: 'activities.redirect_' + this.authenticationService.accessLevel + '_to_app',
            labelTrueDialog: 'generic.confirm',
            labelFalseDialog: 'generic.cancel'
        };

        const translationMap = await this.translate.get(Object.values(dialogConfig.data)).toPromise();
        for (const term in dialogConfig.data) {
            dialogConfig.data[term] = translationMap[dialogConfig.data[term]];
        }

        const modalRef = this.dialog.open(FuseConfirmDialogComponent, dialogConfig);
        const result = await modalRef.afterClosed().toPromise();
        if (result === true) {
            const baseUrl = `${baseAppUrl}#/session?`;
            const secureOptions = _.cloneDeep(options);
            delete secureOptions.exitLessonUrl;

            const [token, redirectPath, encodedAssignmentId, flatOptions] = [
                localStorage.getItem('http_accessToken')?.slice(1, -1),
                'lessons/home',
                assignmentId,
                JSON.stringify(secureOptions)]
                .map(l => encodeURIComponent(l));

            window.location.href = `${baseUrl}token=${token}&redirectPath=${redirectPath}&forceAssignmentId=${encodedAssignmentId}&options=${flatOptions}`;
        }
    }

    /**
     * Return settings value
     */
    public displayLearnerInfo(): boolean {
        return this.lessonsConfigurationService.getDisplayLearnerInfo();
    }

    /**
     * Return list of available add buttons filtered by current user level
     */
    public getAvailableAddButtons(): string[] {
        return this.lessonsConfigurationService.getAvailableAddButtons(this.authenticationService.accessLevel as Roles);
    }

    /**
     * A multimedia activity is a child lesson with limited granule type for children.
     * This methods will :
     *  - create a new lesson with lesson type to 'multimedia'
     *  - affect the new lesson type to be a child of the current lesson
     * @param lessonGranule
     * @return The new lesson as multimedia Activity
     */
    public addChildMultimediaActivity(lessonGranule: LessonGranuleEntity) {
        const metadata = {
            typology: this.typologiesService.getTypologyId(TypologyLabel.multimedia) // multimedia typology label
        };

        return this.lessonCreationService.createLessonAndGranule({}, metadata, false, [], 'lesson').pipe(
            tap(subLessonGranule => {
                this.setActivityAsChildOfLesson(lessonGranule, subLessonGranule);
            })
        );
    }

    /**
     * From granule and only granule, set an activity child of lesson.
     * After that you can retrieve the activity in the lessonGranule.reference array.
     * @param lessonGranule Parent lesson
     * @param activityGranule Could be another lesson for make a multiActivity
     * @return lessonGranule updated
     */
    public setActivityAsChildOfLesson(lessonGranule: LessonGranuleEntity, activityGranule: ActivityGranule|LessonGranuleEntity): Observable<DataEntity> {
        // INFO : There are a difference between granule of lesson and lesson.
        // A granule of lesson is another DataEntity with the values valued by lesson dataEntity
        // For add a child to a lesson we have to add the granule child id to the reference attribute of the parent lesson (and not of the parent granule)

        const lessonEntity = new DataEntity('lesson', {lesson_step: []}, this.octopusConnect, lessonGranule.get('lesson_id'));
        const activityList = lessonGranule.get('reference')
            .map((activity) => +activity.id);
        activityList.push(+activityGranule.id);
        lessonEntity.set('lesson_step', activityList);
        return lessonEntity.save().pipe(take(1));
    }

    /**
     *  From granule and only granule, set activities children of lesson.
     * @param lessonGranule Parent lesson
     * @param activitiesGranules Could includes another lesson for make a multiActivity
     * @param replace If true, current parent lesson references will be erased for activitiesGranules.
     * If false, activitiesGranules will be add next to the already existing children activities
     */
    public setActivitiesAsChildrenOfLesson(lessonGranule: DataEntity, activitiesGranules: DataEntity[], replace = false): Observable<DataEntity> {
        const lessonEntity = new DataEntity('lesson', {lesson_step: []}, this.octopusConnect, lessonGranule.get('lesson_id'));
        const activityList = replace ? [] : lessonGranule.get('reference').map((activity) => +activity.id);

        activityList.push(...activitiesGranules.map(activityGranule => +activityGranule.id));
        lessonEntity.set('lesson_step', activityList);
        return lessonEntity.save().pipe(take(1));
    }

    /**
     * edit and save granule lesson
     * @param resource
     * @param values
     */
    public editGranuleLesson(resource, values): void {
        const lesson =
            new DataEntity(
                'granule',
                resource,
                this.octopusConnect,
                resource.id);
        for (const field in values) {
            lesson.set(field, values[field]);
        }
        lesson.save();
    }

    /**
     * Return list of allowed combination of media.
     * Currently used by multimedia activities.
     */
    public getAllowedMediaTypeCombination(): string[][] {
        return this.lessonsConfigurationService.getAllowedMediaTypeCombination();
    }

    /**
     * Return array of Id. Each id represent the role ID defined by ther server
     *
     * @example `[4, 3]` for manager and administrator (if manager id is 4 and administrator id is 3)
     */
    public getAllowedRoleIdsForModelsCreation(): number[] {
        return this.getAllowedRolesForModelsCreation().map(role => this.roleNameToIdMapping[role]);
    }

    /**
     * Return array of role name.
     *
     * @example `['manager', 'administrator']`
     */
    public getAllowedRolesForModelsCreation(): string[] {
        return this.lessonsConfigurationService.getAllowedRolesForModelsCreation();
    }

    public getAllowedRolesForAutoAssignmentCreation(): string[] {
        return this.lessonsConfigurationService.getAllowedRolesForAutoAssignmentCreation();
    }

    /**
     * Generically call a tool to be executed with the given lesson as reference.
     * @param tool The target tool setting to execute
     * @param currentLesson
     */
    public executeToolFromLesson(tool: { toolIdentifier: string; setting: PluginSetting }, currentLesson: { lesson: DataEntity, step?: number }): Observable<DataEntity> {
        const onComplete = new ReplaySubject<DataEntity>(1);
        const navigationOptions: NavigateToLessonOptions = {exitLessonUrl: this.exitLessonUrl};

        if (currentLesson.hasOwnProperty('step')) {
            navigationOptions.startOnStepIndex = currentLesson.step;
        }

        this.communicationCenter.getRoom(tool.setting.octopusConnectRoom)
            .next('execute', <LessonToolDataCommunicationCenterInterface>{
                lesson: currentLesson.lesson,
                onComplete: onComplete
            });

        return onComplete.pipe(
            tap(() => {
                this.loadAndNavigateToLessonFromAssignment(this.currentAssignment, navigationOptions);
            })
        );
    }

    /**
     * Return the number of assignment created from a lesson.
     *
     * TODO For now, it's return the length of the entities (voluntary not paginated) because the 'count' property is not valued by octopusConnect (count = undefined)
     *
     * @param id unique identifier of the lesson
     */
    public getAssignmentsCountByLessonId(id: string | number): Observable<number> {
        const replaySubjectResults = this.getAssignmentsByLessonId(id);

        return replaySubjectResults.pipe(
            take(1),
            map(dataCollection => dataCollection.entities.length)
        );
    }

    /**
     * Return the assignments created from a lesson.
     *
     * TODO For now, it's return the the entities intentionally not paginated because there a not a lot of assignments for a lesson
     *
     * @param id unique identifier of the lesson
     */
    public getAssignmentsByLessonId(id: string | number): Observable<DataCollection> {
        const replaySubjectResults = new ReplaySubject<Observable<DataCollection>>(1);

        this.communicationCenter.getRoom('assignment').next('getAssignmentsByLesson$', {
            lessonId: id,
            onComplete: replaySubjectResults
        });

        return replaySubjectResults.pipe(
            take(1),
            mergeMap(dataCollection$ => dataCollection$)
        );
    }

    public deleteLessonAssignments(id: string | number): Observable<boolean[]> {
        const replaySubjectResults = new ReplaySubject<Observable<boolean[]>>(1);

        this.communicationCenter.getRoom('assignment').next('deleteLessonAssignments$', {
            lessonId: id,
            onComplete: replaySubjectResults
        });

        return replaySubjectResults.pipe(
            take(1),
            mergeMap(obs => obs)
        );
    }

    public getLessonMetadataDialogFieldsForCurrentRole(): string[] {
        return this.lessonsConfigurationService.getLessonMetadataDialogFields(this.authenticationService.accessLevel as Roles);
    }

    public openLessonMetadataDialog(lessonId: string | number): Observable<MatDialogRef<LessonMetadataDialogComponent>> {
        return this.getLessonObs(lessonId.toString()).pipe(
            take(1),
            map(granuleLesson =>
                this.dialog.open(LessonMetadataDialogComponent, {
                    data: {
                        metadatas: granuleLesson.get('metadatas'),
                        settings: {lessonMetadataDialogFields: this.getLessonMetadataDialogFieldsForCurrentRole()}
                    }
                }))
        );
    }

    /**
     * Return true if dynamic reward must be displayed in the recap.
     * True it's defined if the dynamic milestones are in the settings,
     * False it's defined if the milestones are null, undefined or an empty array in the settings.
     */
    public dynamicRewardIsDisplayed(): boolean {
        const arr = _.get(this, 'getDynamicRewardMilestones()', []);
        return isArray(arr) && arr.length !== 0;
    }

    /**
     * Return the array of milestones from settings and used for the dynamic reward in the recap.
     */
    public getDynamicRewardMilestones(): number[] {
        return this.lessonsConfigurationService.getDynamicRewardMilestones();
    }

    public isAssignmentWithMetacognition(): boolean {
        return this.currentAssignment && this.currentAssignment.get('type_term') &&
            this.currentAssignment.get('type_term').label === 'init';
    }

    public resetActivitiesArray(): void {
        this.currentAssignment = null;
        this.editSubLessonContentEdited = [];
    }

    /**
     * Return true if settings allow the activity list to be filtered by lessons data by default
     */
    public shouldSetDefaultOptionsOnActivityList(): boolean {
        return this.lessonsConfigurationService.getShouldSetDefaultOptionsOnActivityList();
    }

    /**
     * set and save progress for assignment with metacognition, save is in assignation service
     * @param lessonNexted used to know if the next lesson in the assignment will be loaded.
     * so if the current lesson is the last in the assignment, we know the user progress will be 100%
     */
    public saveProgressInAssignmentWithMetacognition(lessonNexted = false): void {
        if (this.authenticationService.userData && +this.currentAssignment.get('assignated_user').uid === +this.authenticationService.userData.id) {
            const percentValues = [0, 33, 66];
            const indexOfCurrentLesson = this.currentAssignment.get('assignated_nodes')
                .findIndex((lesson) => +lesson.id === +this.currentLesson.id);
            const progressValue = lessonNexted && indexOfCurrentLesson === this.currentAssignment.get('assignated_nodes').length - 1 ? 100 : percentValues[indexOfCurrentLesson];
            if (!this.currentAssignment.get('progress') || this.currentAssignment.get('progress') && Math.round(+this.currentAssignment.get('progress')) !== progressValue) {
                this.communicationCenter
                    .getRoom('assignation')
                    .next('saveProgress', {progress: progressValue});
            }
        }
    }

    /**
     * Opens a modal dialog containing the questions set inherent a given lesson
     *
     * @param resource The parent lesson of the displayed step set
     * @param assignment the assignment DataEntity
     */
    public openLessonModal(resource: DataEntity, assignment?: DataEntity): MatDialogRef<LauncherComponent> {
        const dialogConfig = new MatDialogConfig();
        // used to display or not the red disc before assignment
        const isAssignment = !!(assignment && assignment['attributes']['type_term']['label'] !== 'auto');
        dialogConfig.data = {
            resource: resource,
            isAssignment: isAssignment,
            assignment: assignment
        };
        dialogConfig.panelClass = 'question_set_modal';
        dialogConfig.backdropClass = 'backdrop-blur';
        // dialogConfig.maxHeight = window.innerHeight;
        dialogConfig.autoFocus = false;
        return this.dialog.open(LauncherComponent, dialogConfig);
    }

    /**
     * calcul la progression dans un question set (sous-lesson) et lance la sauvegarde de celle ci
     * @param subLessonId
     * @return score  le score de l'utilisateur en % de réussite calculé avant la sauvegarde
     */
    public calculateAndSaveQuestionSetProgress(subLessonId: string, saveScore = true): number {
        const score = this.calculateProgress();
        if (saveScore) {
            this.communicationCenter
                .getRoom('assignment')
                .next('saveSubLessonProgress', {
                    id: subLessonId,
                    score: score.total,
                    scoreByActivities: score.byActivity
                });
        }
        return score.total;
    }

    /**
     * calcul la progression dans un question set (sous-lesson) au total et par activité
     * @return score  le score de l'utilisateur en % de réussite sur le total et un abjet avec le score de l'utilisateur en % de réussite pour chaque activité
     */

    public calculateProgress(): { total: number, byActivity: { [key: string]: number } } {
        const scoreByActivity = {};
        const firstTry: boolean[] = [];

        this.activitiesService.answersProgressBarMultiZone.forEach((answer: AnswerResultInterface) => {
            if (!scoreByActivity[answer.id]) {
                const answers = this.activitiesService.answersProgressBarMultiZone
                    .filter((answerResult) => answerResult.id === answer.id)
                    .map((answerResult) => {
                        return answerResult.state === ItemAnswerStateEnum.currentlyCorrect
                            || answerResult.state === ItemAnswerStateEnum.wasCorrect
                            || !!answerResult.forceStateToCurrentlyCorrect;
                    });

                firstTry.push(answers.every((answer) => answer));
                scoreByActivity[answer.id] = Math.round((answers.filter((answerResult) => answerResult).length / answers.length) * 100);
            }
        });
        return {
            total: Math.round((firstTry.filter((answer) => answer).length / firstTry.length) * 100),
            byActivity: scoreByActivity,
        };
    }

    /**
     * stock la precedante progression dans un question set (sous lesson)
     */
    public initializeProgressInSubLesson(): void {
        if (this.currentAssignment && this.currentAssignment.get('config')) {
            try {
                this.subLessonProgressScoreList = JSON.parse(this.currentAssignment.get('config'));
            } catch (error) {
                console.error(error);
            }
        }
    }

    public shouldDisplayRecapAtEndOfLesson(): boolean {
        return this.lessonsConfigurationService.isRecapAtEndOfLessonEnabled();
    }

    /**
     * launch lesson and assign lesson to current user
     */
    public launchLessonAutoAssignment(
        resource: DataEntity,
        nbrePeople?: number,
        comment?: string,
        startOnStepIndex?: string
    ): void {
        if (this.assignationTypes.length > 0) {
            // get assignation type for assigment creation
            const type = this.assignationTypes.find(
                (asType) => asType.attributes['label'] === 'auto'
            );
            this.communicationCenter
                .getRoom('authentication')
                .getSubject('userData')
                .subscribe((user: DataEntity) => {
                    this.createUserAssignment(
                        user,
                        resource,
                        nbrePeople,
                        comment,
                        type.id,
                        null,
                        startOnStepIndex
                    );
                });
        }
    }

    private initSchoolYearFilterValue(): Observable<void> {
        const getCurrentSchoolYear = () => {
            const usedSchoolYear = (new Date()).getFullYear();
            const month = (new Date()).getMonth();
            return (month < 7) ? usedSchoolYear - 1 : usedSchoolYear;
        };

        let schoolYears$: Observable<DataEntity[]> = of([]);
        if (!!filterByCurrentYearByDefault) {
            schoolYears$ = this.octopusConnect.loadCollection('schoolyears').pipe(
                map(dataCollection => dataCollection.entities),
                take(1)
            );
        }
        return schoolYears$.pipe(
            map(entities => entities.find((schoolYear) => getCurrentSchoolYear().toString() === schoolYear.get('name'))),
            map(schoolYearEntity => !!schoolYearEntity ? schoolYearEntity.id : null),
            map((schoolYearFilterValue: string) => {
                    this.communicationCenter
                        .getRoom('lessons')
                        .next('schoolYearFilterValue', schoolYearFilterValue);
                }
            )
        );

    }

    private postLogout(): void {
        this.resetActivitiesArray();
        if (this.sequencesSubscription) {
            this.sequencesSubscription.unsubscribe();
            this.sequencesSubscription = null;
        }
        this.userLessonsObservable = null;
        this.favoritesLessonsObservable = null;
    }

    private unlockLesson(id: string): void {
        this.getLessonObs(id, true).pipe(
            take(1))
            .subscribe((lesson: DataEntity) => {
                if (lesson.get('assignatedCount') === 0) {
                    lesson.set('locked', false);
                    lesson.save();
                }
            });
    }

    /**
     * Create metadata values of one granule
     * @param metadata : metadata values
     * TODO not really good to remove tagModified & theme here
     */
    private createGranuleMetadata(metadata: MetadataInterface): Observable<DataEntity> {
        delete metadata['tagModified']; // remove tagModified before metadata save
        const metadataTemp = {...metadata};
        delete metadataTemp['theme']; // remove theme before metadata save
        delete metadataTemp['usage']; // remove usage before metadata save
        return this.octopusConnect.createEntity('metadatas', metadataTemp).pipe(take(1));
    }

    private saveProgress(progressValue: number): void {
        if (progressValue === null || progressValue === undefined) {
            throw new Error(`progressValue cannot be null or undefined`);
        } else if (progressValue < 0 || progressValue > 100) {
            throw new Error(`progressValue must be between 0 and 100. Current value : ${progressValue}`);
        }

        this.communicationCenter
            .getRoom('assignation')
            .getSubject('saveProgress')
            .next({progress: progressValue, idAssign: this.currentAssignment.id});
    }

    /**
     * prepare data and launch create user assignment in assignation
     */
    private createUserAssignment(
        user: any,
        resource: any,
        nbrePeople?: number,
        comment?: string,
        typeId?: number | string,
        rating_base?: number,
        startOnStepIndex?: string
    ): void {
        const data = {
            learners: [
                {
                    id: user.id,
                },
            ],
            assignatedCount: nbrePeople ? nbrePeople : 1,
            comment: comment ? comment : '',
            project: null,
            rating_base: rating_base ? rating_base : 4,
            startDate: Date.now(),
            startTime: null,
            dueDate: null,
            dueTime: null,
            nodeId: resource.id,
            group: [],
            type: typeId ? {id: typeId} : null,
        };

        this.communicationCenter.getRoom('assignment').next('createAssignment', {
            assignment: data,
            callback: (assignment) => {
                // lock lesson
                if (resource.get('locked') !== '1') {
                    const entity = new DataEntity(
                        'granule-lesson',
                        resource.attributes,
                        this.octopusConnect,
                        resource.id
                    );
                    entity.set('locked', true);
                    entity.save();
                }
                // launch assignment
                this.communicationCenter
                    .getRoom('assignment')
                    .next('launchAssignmentInPlayer', {assignment, startOnStepIndex});
            },
        });
    }

    private openMetadataEditor(data: { [key: string]: any }, id: string, type: string): Observable<DataEntity> {
        const dialogRef = this.dialog.open(EditLessonDialogComponent, {data});

        return dialogRef.afterClosed().pipe(
            filter(result => !!result),
            mergeMap((result) => this.lessonDuplication(id, type).pipe(
                take(1),
                mergeMap((entity) => this.getLessonObs(entity.get('duplicateGranuleLesson').nid, true)),
                take(1),
                tap((lesson) => this.lessons.push(lesson)),
                mergeMap((lesson) => this.saveFormOrLessonMetadata(lesson.id, result.form.getRawValue(), type).pipe(
                    take(1),
                    map(() => lesson)
                ))
            ))
        );
    }

    public getReferenceOfLessonGranule(granule: LessonGranuleEntity) {
        const lessonId = granule.get('lesson_id');
        return this.octopusConnect.loadEntity('lesson', lessonId) as Observable<LessonReferenceEntity>;
    }

    /**
     * show info on the place where the duplicate lesson is create in the application
     */
    private showInfoLocationDuplicateLesson(): void {
        const dialogConfig = new MatDialogConfig();
        this.translate.get('activities.information').subscribe((translation: string) => {
            dialogConfig.data = {
                titleDialog: translation,
            };
        });

        this.translate.get('activities.duplicate.lesson.location').subscribe((translation: string) => {
            dialogConfig.data.bodyDialog = translation;
        });

        const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
                panelClass: 'lesson-form-dialog',
                data: dialogConfig.data
            }
        );

        dialogRef.afterClosed().subscribe(() => {
            // redirect to "my lessons" tab or to 'my lessons' url
            if (this.lessonsConfigurationService.getIsOnlyModelLesson()) { // no tabs
                this.router.navigate(['lessons/list/lessons']);
            } else {
                this.selectedIndexChange(0);
            }
        });
    }

    public editAndDeleteAssignments(resource: DataEntity) {
        return this.getAssignmentsCountByLessonId(resource.id).pipe(
            take(1),
            mergeMap(count => {
                if (count === 0) {
                    return this.launchEditor(resource.id.toString());
                } else {
                    // l'icon + la modification normale sur les models + la nouvelle sur les lessons
                    return this.dialog.open(EditLessonWarningComponent, {data: {count}}).afterClosed().pipe(
                        take(1),
                        filter((isConfirmed: boolean) => isConfirmed),
                        tap(() => {
                            // It is intentionally in tap and not mergeMap so as not to have to wait for the end of the deletion
                            this.deleteLessonAssignments(resource.id).subscribe();
                        }),
                        mergeMap(() => this.launchEditor(resource.id.toString())),
                    );
                }
            })
        );
    }

    /**
     * save the lesson reference with the activities of the granule lesson
     * @returns {Observable<DataEntity>}
     */
    public saveLessonGranule(lessonGranule: DataEntity): Observable<DataEntity> {
        const granuleLesson = this.activitiesService.generateDataEntity(lessonGranule.attributes, 'granule', lessonGranule.id);
        granuleLesson.set('lesson_id', lessonGranule.get('lesson_id'));

        return granuleLesson.save(true);
    }

    /**
     * save the lesson reference with the activities of the granule lesson
     * @param {number | string} lessonId
     * @param {DataEntity[]} activities
     * @returns {Observable<DataEntity>}
     */
    public saveLessonReference(lessonId: number | string, activities: DataEntity[]): Observable<DataEntity> {
        const lessonEntity = this.activitiesService.generateDataEntity({}, 'lesson', lessonId);
        lessonEntity.set('lesson_step', activities.map((activity) => +activity.id));

        return lessonEntity.save(true);
    }

    public openChooseSubLessonDialog(lessonId: string | number): Observable<string | number | undefined> {
        return this.getLessonObs(lessonId.toString()).pipe(
            take(1),
            mergeMap(lesson => {
                return this.dialog.open(ButtonListDialogComponent, {
                    data: {
                        title: lesson.get('metadatas').title,
                        list: lesson.get('reference').map((subLesson: { id: string | number, title: string }) => {
                            return ({label: subLesson.title, value: subLesson.id});
                        })
                    }
                }).afterClosed();
            })
        );
    }

    public getLessonsCount(options: CollectionOptionsInterface) {
        const finalOptions: CollectionOptionsInterface = merge({
            filter: {
                multi_step: 0,
            },
            range: 0,
        }, options);

        // mettre ce petit "of({})" pour éviter que le paginatedLoadCollection d'octopus-connect ne fasse un appel à l'API tout de suite
        return of({}).pipe(switchMap(() => this.loadPaginatedLessonGranuleCollection(finalOptions))).pipe(map(data => data.paginator.count));
    }

    public getUserLessonsCount(userId: string | number, options: CollectionOptionsInterface) {
        return this.getLessonsCount(merge({filter: {author: userId}}, options));
    }

    public getModelsLessonsCount(options: CollectionOptionsInterface) {
        const modelsCreatorAllowedRoles = this.getAllowedRoleIdsForModelsCreation();
        return this.getLessonsCount(merge({filter: {role: modelsCreatorAllowedRoles}}, options));
    }


    /**
     * Indique si la leçon a des sous-leçons
     * @param currentLesson la leçon à vérifier
     * @param defaultBehavior Si la leçon n'a aucun enfant (ni leçon, ni activité), on ne peut pas déterminer si elle a des sous leçon alors on retourne la valeur par défaut
     */
    public isLessonHasSubLesson(currentLesson: LessonGranuleEntity, defaultBehavior = true) {

        if (currentLesson.get('reference') && currentLesson.get('reference').length === 0) {
            return defaultBehavior;
        }

        return currentLesson.get('reference')?.every((reference) => reference.type === 'lesson');
    }

    public replaceActivityByPositionInLesson(lesson: LessonGranuleEntity, newActivity: ActivityGranule, index: number) {
        const lessonReferenceId = lesson.get('lesson_id');
        const lessonReference = new DataEntity('lesson', {lesson_step: []}, this.octopusConnect, lessonReferenceId);

        // For each reference of the old activity in the lesson, replace it with the new activity
        const activityIdList = lesson.get('reference').map((reference) => reference.id.toString());

        if (activityIdList.length <= index) {
            throw new Error('The index is out of bounds');
        }

        // replace the activity at the specified index
        activityIdList[index] = newActivity.id.toString();

        lessonReference.set('lesson_step', activityIdList);
        return lessonReference.save().pipe(take(1));
    }
}
