import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {CommunicationCenterService} from '@modules/communication-center';
import {ModelSchema, Structures} from 'octopus-model';
import {modulesSettings} from 'app/settings';

import {take} from 'rxjs/operators';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {DemoEndModalComponent} from '@modules/activities/core/player-components/demo-end-modal/demo-end-modal.component';

declare var TaskProxyManager: any;
declare var Platform: any;

const settingsStructure = new ModelSchema({
    APIPEMTask: Structures.boolean(false),
    urlsDemos: Structures.array([{id: 'junior', url: 'https://preprod.lms-futureengineer.api.tralalere.com/sites/default/files/story/LaDonnee/index.html?channelId=2151'},
        {id: 'explorer', url: 'https://prod-citizencode.france-ioi.org/Tralalere/Parcours1/A_sequence_instructions_2/index.html?platform=python&v=4'},
        {id: 'python', url: 'https://prod-citizencode.france-ioi.org/Tralalere/Parcours1/A_sequence_instructions_2/index.html?platform=python&v=4'}]),
});

@Component({
    selector: 'app-demo-external',
    templateUrl: './demo-external.component.html'
})
export class DemoExternalComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild('iframeViewport', {static: true}) iframeViewport: ElementRef;
    @ViewChild('iframeView') iframeView: ElementRef;


    @HostListener('fullscreenchange', ['$event'])
    @HostListener('webkitfullscreenchange', ['$event'])
    @HostListener('mozfullscreenchange', ['$event'])
    @HostListener('MSFullscreenChange', ['$event'])
    screenChange(_event): void {
        this.isFullscreen = !this.isFullscreen;
        this.iframeSizeByRatio();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.iframeSizeByRatio();
    }

    public iframeUrl: SafeResourceUrl;
    public settings: { [key: string]: any };
    private isFullscreen = false;
    public platform: any;
    public sizeinitDone = false;

    constructor(
        protected activatedRoute: ActivatedRoute,
        private changeDetector: ChangeDetectorRef,
        protected communicationCenter: CommunicationCenterService,
        private sanitizer: DomSanitizer,
        private dialog: MatDialog,
        private router: Router,
    ) {
        this.settings = settingsStructure.filterModel(modulesSettings.activities);
    }


    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            let url = '';
            switch (params.get('demoId')) {
                case 'junior':
                    url = this.settings.urlsDemos.filter(u => u.id === 'junior')[0].url;
                    break;
                case 'explorer':
                    url = this.settings.urlsDemos.filter(u => u.id === 'explorer')[0].url;
                    break;
                case'python':
                    url = this.settings.urlsDemos.filter(u => u.id === 'python')[0].url;
                    break;
                default:
                    url = this.settings.urlsDemos.filter(u => u.id === 'python')[0].url;
                    break;
            }

            this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url.includes('?') ? url + '&log=1': url + '?log=1');
        });

        // close sidebar panel when start activity
        this.communicationCenter
            .getRoom('verticalSidebar')
            .getSubject('openOrFoldSidebar')
            .next(false);
        window.addEventListener('message', this.receiveMessage, false);
    }



    receiveMessage = (event) => {
        if (event.data === 'storyLine completed') {
            this.openModal();
        }
    };

    ngAfterViewInit(): void {
        this.iframeSizeByRatio();
    }

    ngOnDestroy(): void {
        window.removeEventListener('message', this.receiveMessage);
        this.communicationCenter
            .getRoom('verticalSidebar')
            .getSubject('openOrFoldSidebar')
            .next(true);
    }


    public onIframeLoad(): void {
        if (this.settings.APIPEMTask && !this.platform) {
            TaskProxyManager.getTaskProxy('taskIframe', (task) => {
                this.platform = new Platform(task);
                TaskProxyManager.setPlatform(task, this.platform);
                task.getMetaData((metaData) => {
                    this.platformLoad(task, this.platform, metaData);
                });
            }, true);
        }

    }

    /**
     * resize the image according to its frame/viewport (in reality it's container iframe will take the same size automatically)
     */
    private iframeSizeByRatio(): void {
        if (this.iframeView && !this.isFullscreen) {
            const iframe = this.iframeView.nativeElement;
            iframe.height = '';
            iframe.width = '';
            iframe.height = +this.iframeViewport.nativeElement.offsetHeight;
            iframe.width = +this.iframeViewport.nativeElement.offsetWidth;
            this.sizeinitDone = true;
        }
    }

    private platformLoad(task, platform, metaData): void {
        this.platform.updateDisplay = (data, success, error) => {
            success();
        };

        this.platform.openUrl = (sTextId, success, error) => {
            success();
        };

        this.platform.validate = (mode, success, error) => {
            console.log('mode : ' + mode);
            if (mode === 'cancel') {
                console.warn('cancel');
                return;
            } else if (mode === 'nextImmediate') {
                console.warn(mode);
            } else if (mode === 'log') {
                // nothing
            } else {
                // ici on réussi l'activité et on ouvre la modale
                this.openModal();
            }
        };

        const loadedViews = {'task': true, 'solution': true, 'hints': true, 'editor': true, 'grader': true, 'metadata': true, 'submission': true};
        task.load(loadedViews, () => {
            // loaded
        });
    }

    openModal(): void {
        const dialogRef = this.dialog.open(
            DemoEndModalComponent, {
                panelClass: 'demo-end-modal-dialog-wrapper'
            }
        );
        dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe(res => {
                this.router.navigate(['register'], {state: {coins: 23}});
            });
    }

    compareArrays(arr1: string[], arr2: string[]): boolean {
        if (arr1.length !== arr2.length) {
            return false;
        }
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return false;
            }
        }
        return true;
    }
}
